export function goFullscreen() {
        console.log('llendo a full screen');
        // Must be called as a result of user interaction to work
        const mf = document.getElementById("bodymaster");
        if (mf) {
          if (mf.webkitRequestFullscreen) {
            mf.webkitRequestFullscreen();
          } else if (mf.mozRequestFullScreen) {
            mf.mozRequestFullScreen();
          } else if (mf.msRequestFullscreen) {
            mf.msRequestFullscreen();
          } else if (mf.requestFullscreen) {
            mf.requestFullscreen();
          }
          mf.style.display = "";
        }
      }
      