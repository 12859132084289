import 'materialize-css/dist/css/materialize.min.css';
import {asignarRol} from "./robotronRobotinterim.js";

document.addEventListener('DOMContentLoaded', function() {
M.AutoInit(); // Inicializa Materialize

console.log('Agregado de roles list');
const rolesList = document.getElementById('roles-list');
console.log("roles List:");
console.log(rolesList);
const roles = ['amigable', 'consejero', 'psicologo', 'medico', 'empatico', 'profesor', 'asesor', 'informativo', 'amable', 'test', 'Seño'];

roles.forEach((rol) => {
    const li = document.createElement('li');
    li.classList.add('collection-item');
    li.id = rol;
    const a = document.createElement('a');
    a.href = '#';
    a.textContent = rol.charAt(0).toUpperCase() + rol.slice(1); // Capitalizar la primera letra
    a.addEventListener('click', () => asignarRol(rol)); // Agregar evento click

    li.appendChild(a);
    rolesList.appendChild(li);
});

});
//initRobotBoton();


// Main application logic (if applicable)
