// "Robotrón IA v2024"
////////////////////////////////////////////////////////////////////////////////////////
/////////// VARIABLES
////////////////////////////////////////////////////////////////////////////////////////    

import  * as config  from './config/newConfigLocal.js';
import $ from 'jquery';
import { goFullscreen } from './fullscreen.js';
import { SR_registerSpeechRecognition, SR_TimerParaFinalOracion } from './sdkSR.js';
import { asignaSR_QrCode, tts, SR_TerminaHabla } from './sdkSpeakAI.js';

import bPopup from 'cpr_bopup';
import "./jquery.qrcode.min.js";

import FileSaver from 'file-saver';
import { menu1 } from "./menu.js";

export let rol_default='';   //Almacena el rol por default

////////////////////////////////////////////////////////////////
//    PROTOCOLO DE COMUNICACIONES MQTT
////////////////////////////////////////////////////////////////
var ws;
var wsUri = "ws:";
var loc = window.location;
console.log(loc);
if (loc.protocol === "https:") { wsUri = "wss:"; }
// This needs to point to the web socket in the Node-RED flow
// ... in this case it's ws/simple
wsUri += "//" + loc.host + loc.pathname.replace("simple","ws/simple");
//wsUri = "wss://infotronico.com:1880/red/ws/robotronComando";
wsUri = config.uriMosquito;
//habilitando el protocolo de comunicaciones
console.log('Inicializando Websockets');
function wsConnect() {    
    ws = new WebSocket(wsUri);
    console.log(ws);
    //var line = "";    // either uncomment this for a building list of messages
    ws.onmessage = function(msg) {
      console.log(msg);
        // parse the incoming message as a JSON object
        var data = msg.data;
        console.log(data);
        PreProcesadorMensajes(data);
        //ws.send(JSON.stringify({data:data}));
    }
    ws.onopen = function() {
        // update the status div with the connection status
        //document.getElementById('status').innerHTML = "connected";
        //ws.send("Open for data");
        console.log("connected");
    }
    ws.onclose = function() {
        // update the status div with the connection status
        // document.getElementById('status').innerHTML = "not connected";
        // in case of lost connection tries to reconnect every 3 secs
        setTimeout(wsConnect,6000);
    }
}

function doit(m) {
    if (ws) { ws.send(m); }
}

//wsConnect();
////////////////////////////////////////////////////////////////

var datarecibido;
    var messenger, buildHTML, messengerActivo;
    var $input, $send, $content, $inner;

    var EtapaDialogo='';  //Usada para registrar la operación dentro del proceso de sugerencias de recetas
                          //Para implementar respuestas y siguientes recetas sugeridas, etc.
                          //Estado inicial=
                          //  '';      Cuando el usuario realiza una consulta inicialmente
                          //  'fin'    Cuando se completó el proceso de consulta (gracias, listo, nada más, etc)
                          //  'elija'  El programa brinda opciones tales como (mostrar, guiarme, enviar a telefono)
                          //           Espera que la persona indique tarea siguiente (otra receta, repetime, etc)
                          //  'wiki'   Si está comentando el contenido de un wiki solicitado
                          //  'musica' Si está reproduciendo música

    var mensajeinicial1=["Hola",
                          "¡Qué tal!",
                          "Bienvenido",
                          "¿Cómo le va?"];
   
    var lastEvent;
    var heldKeys = {};

    var contenidoPopup = $('.mipopup');   //Usado para almacenar el contenido a mostrar en el popup
    var urlparamostrar='';                //Usada para almacenar temporalmente la url a mostrar en el popup   
    var delayAntesQR=50;                  //Usada para calcular retardo antes de mostrar el QR
    var RetardoCerrarQR=15;               //Retardo antes de cerrar el código QR del pop up

    var delayAntesMenu=50;                  //Usada para calcular retardo antes de mostrar el QR
    var RetardoCerrarMenu=120;               //Retardo antes de cerrar el código QR del pop up
    var MenuActivo=false;
    var MenuOpcionesMsg=[];
    var ContenidoMenu='';
    var PopObjMenu;               //Almacena el objeto para cerrar el popup

    var slider=0;     //Variable utilizada para el Objeto Slider
    var CantidadDiapositivas=0;   //Para almacenar cuantas imágenes tiene para mostrar
    var TimerGalleryShow;         //Variable para el timer
    var TiempoSliderShow=3000;    //Tiempo a mostrarse la diapositiva
    var TiempoPrevioGallery=60000;    //Un minuto y medio sin habla y dispara las diapositivas
    var TimerParaGallery;           //Variable que almacena el timer

    
    export var SliderActivo=false;      //True si se está mostrando el Slider
    var ContenidoSlider=false;   //True si el Slider contiene imágenes

    //var Usuario=window.localStorage.getItem("usuario");
    var Usuario = config.UsrImagenesBD;    

    var lnk;

    var sdk;
    var web;

    var preguntausr, respuestarobot;

    var chicaparpadea, chicahabla;

    var ClimaData="";
    var NewsData="";
    var ChistesData="";

    var Menu_Flag=false;
    var Menu_Objeto;
//////////////////////////////////////////////////////////////////////////////////////////
/////// MOTOR PRINCIPAL
//////////////////////////////////////////////////////////////////////////////////////////
//$( function() //Inicia function
//  {
$(document).ready(function() {
    //alert("Usuario:"+Usuario);
    console.log('Iniciando funciones principales');
    if(navigator.platform === 'Win16' || navigator.platform === 'Win32' || navigator.platform === 'WinCE')  
    //if(true) //Para testing sobre webs
    {   messengerActivo=false;

        //$('.modal').modal();    //Inicializa los modales que existan

        //Desactivo la publicidad
        if (0){
        try { ConstruirSlider();  }
          catch(err) { console.log("Error al construir la galeria"); }
        }

        /////////////////////////////////////////////////////////////////////
        //  Botón para dar comienzo a la actividad del Robot
        /////////////////////////////////////////////////////////////////////
        $('#btnIniciarRobot').on('click', function(e) {
                e.preventDefault();

                //Oculta el botón de selección de roles
                $('#seleccion-rol').hide();
                $('#descripcion-rol-container').hide();
                $('#btnIniciarRobot').hide();

                goFullscreen();

                setTimeout(function() {
                  //var Saludo=SaludoHora(mensajeinicial1)+". "+SaludoAleatorio(mensajeinicial2);                  
                  //EnviarTextoBot(Saludo);
                  //TraducirYHablar('Preséntate en una frase de 100 caracteres','es','en');
                  //TraducirYHablar('','es','es');
                  SR_TerminaHabla();  //Para que comience reconocimiento del habla
                }, 500);             

                //Conecta a Websocket para recibir mensajes remotos
                wsConnect();

                SR_registerSpeechRecognition();

                $('#zonavideoparpadea').css('width',window.innerWidth);
                //$('#zonavideoparpadea').css('height',window.innerHeight);
                $('#zonavideoparpadea').css('height',screen.availHeight+60);
                $('#zonavideohabla').css('width',window.innerWidth);
                //$('#zonavideohabla').css('height',window.innerHeight);
                $('#zonavideohabla').css('height',screen.availHeight+60);
                chicaparpadea=document.getElementById('chicaparpadea');
                chicaparpadea.play();
                chicahabla=document.getElementById('chicahabla');
                chicaparpadea.play();

                MuestraBot('Parpadea');

            });
        
        $(document).keypress(function(e) {             //32 Barra espaciadora 
            // Luego reemplazar por señal del sensor de distancia desde Arduino
            if((e.which === 66 || e.which === 98) && SliderActivo)  //La persona está a más de 1 mt
                {
                    console.log(e.which);
                    LlamarLaAtencion();                    
                }
            if((e.which === 65 || e.which === 97) && SliderActivo) //La persona está a menos de 1 mt
                {
                    console.log(e.which);
                    DespertarRobot();                    
                }
            });
        
        //--- FUNCION CREADA PARA LA DEMO CON UN BOTON AZUL QUE SE VISIBILIZA CUANDO COMIENZA LA GALERIA ---
        $('#btnDespertarRobot').bind('click', function(e) {
              e.preventDefault();
              LlamarLaAtencion();
              $('#btnDespertarRobot').hide();
          });
    }else
    {
        messenger = new Messenger();
        buildHTML = new BuildHTML();
        console.log('Sector de mobile');
        $input = $('#input');
        $send = $('#send');
        $content = $('#content');
        $inner = $('#inner');
        $enter = $('#enter');   //Boton de entra
        $salir = $('#salir'); //Boton de salir
        
        //messenger.MsgOnSend = MessengerBuildSent;
        //messenger.MsgOnRecieve = MessengerBuildRecieved;
        

        $send.on('click', function(e) {
          MessengerSendMessage();
        });

        $enter.on('click', function(e) {
          EntrarChat();
        });

        $salir.on('click', function(e) {
          SalirChat();
        });
        
        $input.on('keydown', function(e) {
          key = e.which || e.keyCode;          
          if (key === 13) { // enter key
            e.preventDefault();            
            MessengerSendMessage();
          }
        });

        messengerActivo=true;
    }

  }); //Termina function

  function DespertarRobot(e)
    {
        clearTimeout(SR_TimerParaFinalOracion);
        ActivarTimerParaGallery();
        //Reiniciar TimerParaGallery si hay alguien al frente del rebot
        DetenerSlide();
        //Realizar Saludo inicial
        //EnviarTextoBot(SaludoHora(mensajeinicial1));
        EnviarTextoBot(SaludoHora(mensajeinicial1)+" "+RespuestaAleatoria(Llamaratencion));
    }

  function LlamarLaAtencion()
    {
        clearTimeout(SR_TimerParaFinalOracion);
        ActivarTimerParaGallery();
        DetenerSlide();
        //Envia un mensaje a la persona que pasa por delante
        EnviarTextoBot(SaludoHora(mensajeinicial1)+" "+RespuestaAleatoria(Llamaratencion));
    }

  function DetenerSlide()
    {
      if (ContenidoSlider) {
        if (SliderActivo===true) {
          SliderActivo=false;
          clearTimeout(TimerGalleryShow);
          var t=setTimeout(function(){
              $('#Gallery').fadeOut(300, function() {});
              //$('#zonavideoparpadea').fadeOut(300, function() {});
              //$('#zonavideoparpadea').fadeIn(3000, function() {});
            },300);
        }
      }
    }

  function ComenzarSlide()
    { //Muestra el botón para activar el robot
      $('#btnDespertarRobot').show();

      if (ContenidoSlider) {
        try{
          clearTimeout(SR_ActivarTimerReconociendo);
          clearTimeout(SR_TimerParaBalbuceo);
          clearTimeout(SR_TimerParaFinalOracion);
          clearTimeout(TimerParaGallery);
        }catch(e){console.log('Advertencia en limpiar timers - ComenzarSlide');}
          verGrafico("");
          SR_recognition.abort();
          
          slider=0;
          SliderActivo=true;
          var t=setTimeout(function(){
              $('#zonavideoparpadea').fadeOut(300, function() {});
              $('#zonavideohabla').fadeOut(300, function() {});
              $('#Gallery').fadeIn(3000, function() {});
            },300);

      TimerGalleryShow=setInterval(function(){MostrarSliderActual();},TiempoSliderShow);
      }
      return;
    }

  function MostrarSliderActual(e)
    {   
        slideActual=slider+1;        
        if (slideActual===CantidadDiapositivas) {slideActual=0;}
        //if (slider!==0) 
            //{ $('#imagen_'+slider).fadeOut(300, function() {}); }
        $('#imagen_'+slider).fadeOut(300, function() {});
        setTimeout(function(){
                $('#imagen_'+slideActual).fadeIn(300, function() {});
              },300);
        
        slider=slideActual;   //Almacena el slider viejo para borrarlo al reingresar
        return;
    }

  function ConstruirSlider()
    { 
      var html='';
      var cantidad=0;
      var diapos=document.getElementById('carrousell');
      var listado=document.createElement('ul');
      listado.setAttribute('style', 'margin-block-start: 0px'); 
      listado.setAttribute('style', 'margin-block-end: 0px'); 
      listado.setAttribute('style', 'margin-inline-start: 0px'); 
      listado.setAttribute('style', 'margin-inline-end: 0px'); 
      listado.setAttribute('style', 'text-align: center'); 
      listado.style.margin="0px";
      listado.style.position="fixed";

      //diapos.innerHTML='';
      try {
        $.post(websiteTools+'publicity.php', {Usuario : Usuario}, 
          function(data){
                //console.info("Recibido: "+data);
                lnk=JSON.parse(data);
                //console.log(lnk.length);
                for (var i = lnk.length - 1; i >= 0; i--) {
                  //console.log(lnk[i].link);
                  //html=html+'<li><img style="display:none; height: '+window.innerWidth+'; width: '+window.innerWidth-30+'px; background-position: center; background-repeat: no-repeat; background-size: cover; '+ 
                  //  ' background-image: url(\''+lnk[i].link+'\'); " id="imagen_'+cantidad.toString()+'"/></li>\n';
                  html=html+'<li><img style="display:none; width: '+(window.innerWidth-80)+'px; height: '+window.innerHeight+'px;"'+ 
                    ' src="'+lnk[i].link+'" id="imagen_'+cantidad.toString()+'"/></li>\n';
                  cantidad=cantidad+1;
                }
                listado.innerHTML = html;
                diapos.appendChild(listado);
                if (cantidad>0) 
                  {//Activar bandera de carrousell
                      ContenidoSlider=true;
                      CantidadDiapositivas=cantidad;
                  }
            })
          .fail(function()
            {console.log('Hubo un error en la publicidad');
            });
      } catch (err) {
        // Nohay imágenes para mostrar.
        console.log("Hubo un error al comunicarse con el servidor");
      }
  
      
    }

  function ActivarTimerParaGallery()
    {
        try{clearInterval(TimerParaGallery);}
        catch(e){console.log("No se pudo limpiar IntervalTiempoSilencio");}        
        //console.log("Inicia timer previo Gallery");
        TimerParaGallery=setTimeout(
          function(){
            console.log("Iniciando Gallery");
            ComenzarSlide();
          }, TiempoPrevioGallery);
        return;
    }
////////////////////////////////////////////////////////////////////////////////////////
/////////// RUTINAS DE FUNCIONES VARIAS
////////////////////////////////////////////////////////////////////////////////////////
  //Elige una respuesta de un array aleatoriamente incluyendo saludos como buenos dias agregados al array
  //Usado para el primer saludo del diálogo
  function SaludoHora(arreglo)
    { var alternativas=2;
      var numero=Math.floor(Math.random()*alternativas);
      //console.log("Alternativa aleatoria:"+numero);
      var saludotexto="";
      if (numero===0) 
      {
        //Crea un saludo de acuerdo a la hora
        var saludo = "";
        var f=new Date();
        var hora=f.getHours();
        //var hora=23; //Para testing
        if(hora>=6 && hora<13){
                saludo=["Buen día",
                          "Muy buenos días",
                          "Tenga un muy buen día",
                          "Buenos días"];
            }
        if(hora>=13 && hora<21){ 
                saludo=["Buena tarde",
                          "Muy buenas tardes",
                          "Tenga una excelente tarde",
                          "Buenos tardes"];
            }
        if (hora>=21 && hora <=24 || hora<6){ 
                saludo=["Buenas noches",
                          "Muy buenas noches",
                          "Tenga una excelente velada",
                          "Buenos noches"];
            }

            numero_elementos=saludo.length;
            numero=Math.floor(Math.random()*numero_elementos);
            saludotexto=saludo[numero];
      }else
      {
        var numero_elementos=arreglo.length;
        numero=Math.floor(Math.random()*numero_elementos);
        saludotexto=arreglo[numero];
      }
      return saludotexto;
    }

  //Elige una respuesta de un array aleatoriamente
  function SaludoAleatorio(arreglo)
    { 
      var saludotexto="";
      var numero_elementos=arreglo.length;
      var numero=Math.floor(Math.random()*numero_elementos);
      saludotexto=arreglo[numero];
      
      return saludotexto;
    }    

  //Elige una respuesta de un array aleatoriamente
  function RespuestaAleatoria(arreglo)
    { //Devuelve una respuesta aleatoria dentro de un array que contiene varios mensajes posibles
      var respuestatexto="";
      var elementos=arreglo.length;
      var numero=Math.floor(Math.random()*elementos);
      respuestatexto=arreglo[numero];
      
      return respuestatexto;
    }      

  //Devuelve true si existe coincidencia con el tema dentro de un array que contiene varios mensajes posibles
  //Simula red neuronal
  function EvaluarSiCoincideExpresion(expresion)
    { 
      var respuesta=false;
      var elementos=arreglo.length;
      var numero=Math.floor(Math.random()*elementos);
      respuestatexto=arreglo[numero];
      
      return respuestatexto;
    }      

  function HoraActual()
    {
      var fecha = new Date();
      var hora=fecha.getHours();
      var minutos=fecha.getMinutes();
      var horaactual="Hora "+hora+" y "+minutos+" minutos";
      console.log(horaactual);
      return horaactual;
    }
////////////////////////////////////////////////////////////////////////////////////////
/////////// RUTINAS DE PREPROCESAMIENTO DEL DIALOGO PREVIO AL ANALISIS AUTOMATICO IA
////////////////////////////////////////////////////////////////////////////////////////
  // Analiza el texto que la persona le dice al robot en busca de palabras claves o
  // comandos como cambiar a modo turismo, mostrar una url, mostrar una publicidad
    var HolaCortana=['Disculpe, pero Cortana es una prima hermana medio tarada',
                     'No, no soy cortana, usted se confunde',
                     '¿Tengo cara de cortana?',
                     '¿Es que acaso parezco una computadora de escritorio?'];
    var HolaSiri=['Disculpe, pero Siri es un medio hermano medio estúpido',
                     'No, no soy siri, usted se confunde',
                     '¿Tengo cara de siri?',
                     '¿Es que acaso parezco una tonta computadora de escritorio?'];
    var Abusivo=['Disculpe, pero le pido más respeto',
                     '¡Aunque soy una máquina, puedo entender eso y es desagradable!',
                     '¿Tengo cara de no entender sus palabrotas?',
                     'Por favor, usted parece una persona decente.'];                   

  export function PreProcesadorMensajes(msj)
    {   var mensaje=msj;        
        mensaje=mensaje.toLowerCase();
        //AlmacenaPreguntayRespuesta();        

        console.log('PreProcesadorMensajes:'+mensaje);
        if ((mensaje.indexOf('despierta chinita')>-1
          ||mensaje.indexOf('vamos despertate')>-1
          ||mensaje.indexOf('vamos despierta')>-1
          ||mensaje.indexOf('despierta robot')>-1
          ||mensaje.indexOf('despertate robot')>-1
          ||mensaje.indexOf('hola robot')>-1
          ||mensaje.indexOf('hola chinita')>-1
          ||mensaje.indexOf('hola robotron')>-1) && SliderActivo===true)
          {
              DespertarRobot();
              return;
          }

        //Si el Slider está activo no procesa el habla.
        if (SliderActivo===true) {SR_ProcesandoReconocimiento=false; return;}  

        if ((mensaje.indexOf('la hora')>-1
          ||mensaje.indexOf('hora son')>-1
          ||mensaje.indexOf('hora es')>-1) 
          && SliderActivo===false)
          { mensaje='hora actual'; }

        if (mensaje.indexOf('clima')>-1 || mensaje.indexOf('temperatura')>-1
          || (mensaje.indexOf('clima')>-1 && mensaje.indexOf('hoy')>-1) 
          && SliderActivo===false)
          { mensaje='clima hoy'; }

        if (mensaje.indexOf('noticia')>-1 && mensaje.indexOf('fresca')>-1
          || (mensaje.indexOf('noticia')>-1 && mensaje.indexOf('actual')>-1)
          || (mensaje.indexOf('noticia')>-1 && mensaje.indexOf('actualidad')>-1)
          || (mensaje.indexOf('noticia')>-1 && mensaje.indexOf('ultimo')>-1 && mensaje.indexOf('momento')>-1)
          || (mensaje.indexOf('noticia')>-1 && mensaje.indexOf('último')>-1 && mensaje.indexOf('momento')>-1)
          || (mensaje.indexOf('noticia')>-1 && mensaje.indexOf('hoy')>-1) 
          && SliderActivo===false)
          { mensaje='noticia ultimo momento'; }

        if ((mensaje.indexOf('noticia')>-1 || mensaje.indexOf('novedad')>-1 
          || mensaje.indexOf('primicia')>-1 || mensaje.indexOf('promocion')>-1 || mensaje.indexOf('promoción')>-1)
          && ((mensaje.indexOf('sport')>-1 || mensaje.indexOf('asphalt')>-1
          || mensaje.indexOf('aspol')>-1 || mensaje.indexOf('espol')>-1))
          && SliderActivo===false)
          { mensaje='noticia cliente'; }

        if (mensaje.indexOf('graba')>-1 && mensaje.indexOf('mensajes')>-1
          || (mensaje.indexOf('grabar')>-1 && mensaje.indexOf('chat')>-1)
          || (mensaje.indexOf('guarda')>-1 && mensaje.indexOf('chat')>-1)
          || (mensaje.indexOf('guarda')>-1 && mensaje.indexOf('conversación')>-1)
          || (mensaje.indexOf('graba')>-1 && mensaje.indexOf('chat')>-1)
          || (mensaje.indexOf('exporta')>-1 && mensaje.indexOf('mensajes')>-1)
          || (mensaje.indexOf('exporta')>-1 && mensaje.indexOf('chat')>-1)
          || (mensaje.indexOf('exportar')>-1 && mensaje.indexOf('mensajes')>-1)
          || (mensaje.indexOf('exportar')>-1 && mensaje.indexOf('chat')>-1)
          || (mensaje.indexOf('graba')>-1 && mensaje.indexOf('conversación')>-1)
          || (mensaje.indexOf('exporta')>-1 && mensaje.indexOf('conversación')>-1)
          || (mensaje.indexOf('grabar')>-1 && mensaje.indexOf('conversación')>-1)
          || (mensaje.indexOf('exportar')>-1 && mensaje.indexOf('conversación')>-1)
          && SliderActivo===false)
          { mensaje='exporta char a word'; }
        
        if (mensaje.indexOf('muestra')>-1 && mensaje.indexOf('último')>-1 && mensaje.indexOf('mensaje')>-1        
          || (mensaje.indexOf('ver')>-1 && mensaje.indexOf('último')>-1 && mensaje.indexOf('mensaje')>-1 )
          || (mensaje.indexOf('muéstrame')>-1 && mensaje.indexOf('último')>-1 && mensaje.indexOf('mensaje')>-1 )
          || (mensaje.indexOf('muestrame')>-1 && mensaje.indexOf('último')>-1 && mensaje.indexOf('mensaje')>-1 )
          && SliderActivo===false)
          { mensaje='muestra mensaje'; }

        /* if (mensaje.indexOf('chiste')>-1
          || mensaje.indexOf('humor')>-1 
          || mensaje.indexOf('broma')>-1 
          || mensaje.indexOf('reir')>-1 
          || mensaje.indexOf('gracioso')>-1 
          || mensaje.indexOf('simpatico')>-1 
          || mensaje.indexOf('simpático')>-1 
          || mensaje.indexOf('cuentito')>-1 
          && SliderActivo===false)
          { mensaje='chiste'; } */

        if ((mensaje.indexOf('menu')>-1 && mensaje.indexOf('opciones')>-1)
          || (mensaje.indexOf('menú')>-1 && mensaje.indexOf('opciones')>-1)
          || (mensaje.indexOf('menú')>-1 && mensaje.indexOf('opcion')>-1)
          || (mensaje.indexOf('menu')>-1 && mensaje.indexOf('opcion')>-1)
          || (mensaje.indexOf('menu')>-1)
          || (mensaje.indexOf('menú')>-1)
          && SliderActivo===false)
          { mensaje='menuopcionestester'; }
          //{ mensaje='menuopciones'; }


         /*-------------------------------*/
        /* Bloque de módulos específicos */
        /*-------------------------------*/
        /*----------- Bloque Menu -----------*/
        if (EtapaDialogo==='menu' &&
          (mensaje.indexOf('fin')>-1 || mensaje.indexOf('Cerrar')>-1)
          && SliderActivo===false)
          { mensaje='cerrar menu'; console.log("Deteniendo Bloque de menú...");}
        
        //PROCESA LAS OPCIONES DEL MENU
        else if (EtapaDialogo==='menu' && SliderActivo===false)
          { 
            Menu_Objeto.DiccionarioRespuestas.forEach(opcionposible => {
              if(mensaje.indexOf(opcionposible.valor)>-1)
              {
                console.log("- Encontró coincidencia -");
                console.log(opcionposible);
                console.log(opcionposible.opcion);
              }
            });
            return; 
          }
          //Está esprando opción y no se pronunció ninguna opción válida
        else if (EtapaDialogo==='menu'){ return; }
            //Está esprando opción y no se pronunció ninguna opción válida
        else{ 
            //Sigue el análisis normal
        }

        /*--------------------------------------*/
        /* -----Bloque de módulos específicos --*/
        /*--------- Bloque Recepcionista -------*/
        /*--------------------------------------*/
        
        if (
           ((mensaje.indexOf('busco')>-1 || mensaje.indexOf('buscando')>-1 || mensaje.indexOf('buscaba')>-1) && 
              (mensaje.indexOf('alguien')>-1||mensaje.indexOf('persona')>-1||mensaje.indexOf('representante')>-1||
              mensaje.indexOf('personal')>-1||mensaje.indexOf('atienda')>-1||mensaje.indexOf('información')>-1))
         ||((mensaje.indexOf('necesito')>-1 || mensaje.indexOf('necesitando')>-1 || mensaje.indexOf('necesitaba')>-1) && 
              (mensaje.indexOf('alguien')>-1||mensaje.indexOf('persona')>-1||mensaje.indexOf('representante')>-1||
              mensaje.indexOf('personal')>-1||mensaje.indexOf('atienda')>-1||mensaje.indexOf('información')>-1))
         ||(mensaje.indexOf('hablar')>-1 && 
              (mensaje.indexOf('alguien')>-1||mensaje.indexOf('persona')>-1||mensaje.indexOf('representante')>-1||
              mensaje.indexOf('personal')>-1||mensaje.indexOf('atienda')>-1||mensaje.indexOf('información')>-1))
         ||((mensaje.indexOf('comunicar')>-1 || mensaje.indexOf('comunicarme')>-1 || mensaje.indexOf('comunique')>-1) && 
              (mensaje.indexOf('alguien')>-1||mensaje.indexOf('persona')>-1||mensaje.indexOf('representante')>-1||
              mensaje.indexOf('personal')>-1||mensaje.indexOf('atienda')>-1||mensaje.indexOf('información')>-1))
        && SliderActivo===false)
        { mensaje='Inicia busca contacto'; }


        if (EtapaDialogo==='Confirma busca contacto' &&
         (mensaje.indexOf('si')>-1 || mensaje.indexOf('afirmativo')>-1
          || mensaje.indexOf('no')>-1 || mensaje.indexOf('negativo')>-1)
          && SliderActivo===false)
          { mensaje='ingredientes'; }

        else if (EtapaDialogo==='Busca contacto' &&
          (mensaje.indexOf('preparación')>-1 || mensaje.indexOf('preparacion')>-1
           || mensaje.indexOf('como se hace')>-1 || mensaje.indexOf('como es')>-1
           || mensaje.indexOf('como lo hago')>-1 || mensaje.indexOf('como la hago')>-1)
           && SliderActivo===false)
           { mensaje='preparación'; }

        else if (EtapaDialogo==='Busca contacto' &&
           (mensaje.indexOf('previa')>-1 || mensaje.indexOf('anterior')>-1
            || mensaje.indexOf('antes')>-1 || mensaje.indexOf('precedente')>-1
            || mensaje.indexOf('preliminar')>-1 )
            && SliderActivo===false)
            { mensaje='receta previa'; }

        else if (EtapaDialogo==='Busca contacto' &&
           (mensaje.indexOf('siguiente')>-1 || mensaje.indexOf('próxima')>-1
            || mensaje.indexOf('más receta')>-1 || mensaje.indexOf('mas receta')>-1)
            && SliderActivo===false)
            { mensaje='siguiente receta'; }

        else if (EtapaDialogo==='Busca contacto' &&
            (mensaje.indexOf('buscar')>-1 || mensaje.indexOf('otra vez')>-1
             || mensaje.indexOf('nuevamente')>-1 || mensaje.indexOf('de nuevo')>-1
             || mensaje.indexOf('nueva receta')>-1 || mensaje.indexOf('otra receta')>-1)
             && SliderActivo===false)
             { mensaje='buscar nuevamente'; }
        else{  //   
        }
        /*------ Fin bloque Recepcionista -------*/

        /* -----------------------------------*/
        /*    Fin del bloque específico       */

        console.log("Mensaje: "+mensaje);
        switch(mensaje) {
          // ****** OPCIONES PREDETERMINADAS PARA ACCIONES Y FUNCIONES CONCRETAS ******
          case 'hora actual':
              EnviarTextoBot(HoraActual());
              break;
          case 'hola cortana':
              EnviarTextoBot(RespuestaAleatoria(HolaCortana));
              break;
          case 'hola siri':
              EnviarTextoBot(RespuestaAleatoria(HolaSiri));
              break;
          case 'abusivo':
              EnviarTextoBot(RespuestaAleatoria(Abusivo));
              break;
          case 'clima hoy':
              Clima("Hoy");
              break;     
          case 'noticia ultimo momento':
              Noticia("UltimoMomento");
              break;
          case 'noticia cliente':
              NoticiaCliente();
              break;
          case 'chiste':
              Chiste();
              break;
          case 'exporta char a word':
              exportarHistorialConversacion();
              break;
          case 'muestra mensaje':
              muestraultimomensaje();
              break;
          case 'menuopcionestester':              
              EnviarTextoBotMenu("Tiene varias alternativas para elegir. Se las presento.", "menu1");
              EtapaDialogo='menu';
              break;
          case 'cerrar menu': //Finaliza la selección de opciones del menú
              //Menu_Flag=false;
              EtapaDialogo='';
              CerrarMenu("menu1");
              break;

          // ******* PROCESAMIENTO DE SOLICITUD DE CONTACTO CON PERSONAL ******* //
          case 'Inicia busca contacto':
            EtapaDialogo='elija';
            recetaresultado=ConsultaPersonas(Rec_ProcesaPregunta(mensaje));
            console.log("recetaresultado:");
            console.log(recetaresultado); 
            break;
          case 'Busca contacto':
              console.log("Indicando ingredientes...");
              EnviarTextoBot("Ingredientes de la receta: "+RecetaActual.ingredientes);
              break;
          case 'preparación':
              console.log("Indicando preparación...");
              EnviarTextoBot("Preparación de la receta: "+RecetaActual.preparacion);
                break;
          case 'receta previa':
              //Buscar una nueva aleatoriamente                
              EtapaDialogo='elija';
              console.log("Previa receta...");
              recetaresultado=PreviaReceta();
              console.log(recetaresultado);
              break;    
          case 'siguiente receta':
              //Buscar una nueva aleatoriamente                
              EtapaDialogo='elija';
              console.log("Siguient receta...");
              recetaresultado=SiguienteReceta();
              console.log(recetaresultado);
              break;            
          case 'buscar nuevamente':
              console.log("Buscar nuevamente...EtapaDialogo=''");
              EtapaDialogo='';
              EnviarTextoBot("Bueno, puedes hacer tu consulta nuevamente");
              break;

          case 'menuopciones':
              EnviarTextoBotMenu("Tiene varias alternativas para elegir. Se las presento.");
              break;
          default:
              // Función original que se dirigía al end point de IBM
              //EnviaTextoServerIot(mensaje);
              //Función que envía el mensaje a la IA en localhost (para pruebas)
              TraducirYHablar(mensaje);
          }
    }


  /* ***********************************************
   * ***********************************************
   *    PROCESAMIENTO DE UN MENÚ DE OPCIONES
   * ***********************************************
   * ********************************************* */
  function ConstruirMenu(idmenu)
    {      
      console.log(idmenu);
      console.log(menu1);
      var html='';
      var Opciones=[];
      //const Menu_Objeto=window[idmenu];   //OBTIENE EL CONTENIDO DEL MENU DESDE SU VARIABLE
      const Menu_Objeto=menu1;   //OBTIENE EL CONTENIDO DEL MENU DESDE SU VARIABLE
      console.log(Menu_Objeto);
      for (var i = 0; i < Menu_Objeto.CantidadOpciones; i++) {
        Opciones[i]=Menu_Objeto.OpcionesMenu[i];      //Elimina el ) de los elementos del array        
        //console.log(Opciones[i]);
        html='<button id="menu'+i+'" class="carousel-item" onclick="RespuestaMenu(\''+
            Opciones[i]+'\', \''+Menu_Objeto.Acciones[i]+'\');">'+Opciones[i]+'</button>';
        console.log(html);
        $('#carrouselopciones').append(html);
      }            
      //console.log(html);
      //$('#carrouselopciones').html(html);
      //$(document).on('click','#btnPrepend',function(){RespuestaMenu(respuesta);})
      return;
    }

  function EsperaParaMostrarMenu(mensaje)
    {
        setTimeout(function () 
            {
              console.log('A mostrar el Menu');
              var segundos=RetardoCerrarMenu + 1000; 
                var lap=setInterval(function(){ segundos=segundos-1; $('#segundosmenu').html(segundos+' seg');}, 1000);                  

                //$('#modal2').modal('open');
                $('#popupmenu').bPopup({
                    autoClose: segundos*1000, //Auto closes después de 
                    position: [100, 100], //x, y
                    speed: 650,
                    transition: 'slideDown',
                    transitionClose: 'slideUp',
                    onClose: function() {
                        //contenidoPopup.empty();
                        //$('#multi').html('');
                        //$('#textomulti').html('');
                        clearTimeout(lap);
                        //asignaSR_QrCode(false);  //Ya no se usa porque debe reconocer el número elegido
                        SR_TerminaHabla(); //Vuelve a reconocimiento del habla
                    }
                  }, function(){               
                  $('.carousel').carousel({
                    fullWidth: false,     //Ancho completo
                    indicators: true,     //Puntos que indican cuantos son los botones
                    shift: 50,            //Espacio entre botones (superposición)
                    numVisible: 5,        //Elementos visibles
                    noWrap: false})      //Circular                  
                  });
                PopObjMenu = $('#popupmenu').bPopup();
                //PopObjMenu.close();
            }, mensaje.length * delayAntesQR);
    }

  function CerrarMenu()
    {
      $('.carousel').destroy();
    }

  function RespuestaMenu(respuesta, accion)
    {      
      console.log('respuesta: '+respuesta + '  acción:'+accion);
      switch(mensaje) {
        // ****** OPCIONES PREDETERMINADAS PARA ACCIONES Y FUNCIONES CONCRETAS ******
        case 'hora actual':
            EnviarTextoBot(HoraActual());
            break;
        case 'hola cortana':
            EnviarTextoBot(RespuestaAleatoria(HolaCortana));
            break;
        default:
      }
      return;
    }

  
  //Función vieja no utilizada. Solo para fines de copia del código
  function PreProcesadorOpcionesMenu(eleccion)
    {   //Sintaxis metalenguaje:  (textoopcion, accionfunction, confirmarsino)        
        //Separar opciones contenidas en el menú
        //Esto puede requerir una librería específica para el cliente en forma de sdkCliente.js
        PopObjMenu.close(); 

        if (eleccion.indexOf('uno')>-1){eleccion='1';}
        if (eleccion.indexOf('dos')>-1){eleccion='2';}
        if (eleccion.indexOf('tres')>-1){eleccion='3';}
        if (eleccion.indexOf('cuatro')>-1){eleccion='4';}
        if (eleccion.indexOf('cinco')>-1){eleccion='5';}
        if (eleccion.indexOf('seis')>-1){eleccion='6';}
        if (eleccion.indexOf('siete')>-1){eleccion='7';}
        if (eleccion.indexOf('ocho')>-1){eleccion='8';}
        if (eleccion.indexOf('nueve')>-1){eleccion='9';}
        if (eleccion.indexOf('cero')>-1){eleccion='0';}
        
        console.log('PreProcesadorMenú Opción:'+eleccion);
        console.log('textoopcion:'+MenuOpcionesMsg.OpcionesMenu[eleccion].opcion);
        var opcion=MenuOpcionesMsg.OpcionesMenu[eleccion].opcion;
        var accion=MenuOpcionesMsg.OpcionesMenu[eleccion].accion;
        var confirmar=MenuOpcionesMsg.OpcionesMenu[eleccion].confirma;
        //console.info(MenuOpcionesMsg);
        if (MenuOpcionesMsg==='') {eleccion='Error';}        
        if (MenuOpcionesMsg.OpcionesMenu[eleccion].opcion==='Cancelar') {eleccion='Cancelar';}        
        switch(eleccion) {
          case 'Error':
              EnviarTextoBot('Disculpe. No he comprendido bien, por favor inténtelo nuevamente.');
              break;
          case 'Cancelar':
              EnviarTextoBot('De acuerdo. He cancelado la selección.');
              break;
          default:
              EnviarTextoBot('Ha elegido '+MenuOpcionesMsg.OpcionesMenu[eleccion].opcion);
              //window["functionName"](arguments);
              window[accion](opcion);
          }
        return false;
    }

  //Función vieja no utilizada. Solo para fines de copia del código
  function SepararContenidoMenu(ContenidoMenu)
    {
      var partes=ContenidoMenu.split("(",10);
      var opcion=[];
      var accion=[];
      var confirma=[];
      partes.splice(partes.indexOf(''),1);        //Remueve el "" del primer elemento
      partes[i]=partes[i].replace(/\'/g,'');      //Elimina el ' de los elementos del array
      partes[i]=partes[i].replace(/\"/g,'');      //Elimina el ' de los elementos del array
      //var obj={"OpcionesMenu":[{"opcion" :''},{"accion":''},{"confirma":''}]};
      var obj={'OpcionesMenu':[{"opcion":'',"accion":'',"confirma":''}]};
      for (var i = 0; i < partes.length; i++) {
        partes[i]=partes[i].replace(/\)/g,'');      //Elimina el ) de los elementos del array
        var parametros=partes[i].split(",",10);
        console.log(i);
        console.info(parametros);
        obj['OpcionesMenu'].push({"opcion":parametros[0],"accion":parametros[1],"confirma":parametros[2]});
      }
      obj['OpcionesMenu'].splice(0,1);
      return obj;
    }
//////////////////////////////////////////////////////////
/////// REACCIONES DEL BOT 
//////////////////////////////////////////////////////////
  function EnviarTextoBotMenu(mensaje, menu)
    {   verGrafico('micbloqueado');
        ConstruirMenu(menu);
        asignaSR_QrCode(true);   //Flag usada para evitar el reconocimiento de la respuesta
        Menu_Flag=true;
        console.log('Agregando mensaje al bot y Menú en pop up');
        clearTimeout(SR_TimerParaFinalOracion);
        tts(mensaje);
        EsperaParaMostrarMenu(mensaje); 
        
        //ActivarTimerParaGallery();
    } //Fin EnviarTextoBot

  function EnviarTextoBotQR(mensaje)
    {   verGrafico('micbloqueado');
        asignaSR_QrCode(true);
        console.log('Agregando mensaje al bot y QR en pop up');
        respuestarobot=mensaje;
        clearTimeout(SR_TimerParaFinalOracion);
        tts(mensaje);
        EsperaParaMostrarQR(mensaje);
        
        ActivarTimerParaGallery();
    } //Fin EnviarTextoBot

  function EnviarTextoBot(mensaje)
    { verGrafico('micbloqueado');
      console.log('Agregando mensaje al bot');
      asignaSR_QrCode(false);
      respuestarobot=mensaje;
      var _mensaje=mensaje;
      tts(mensaje);
      clearTimeout(SR_TimerParaFinalOracion);

      //ActivarTimerParaGallery();
    } //Fin EnviarTextoBot

  //Función que busca una url en el texto y lo separa en dos partes,
  //agregando la instrucción para capturar el código QR en el teléfono
  //Luego devuelve el mensaje para continuar su tratamiento
  function SeparaUrl(texto)
    { //Busca http o www
      var instruye='. Con tu teléfono captura el código QR.';
      var instruyemenu='. Indica el número que eliges.';
      var inicio;
      var resultado=false;
      var msg=texto.toLowerCase();
      if (msg.indexOf("url:") > -1)
      {   inicio=msg.indexOf("url:");
          urlparamostrar = msg.substring(inicio+4);
          console.log(urlparamostrar);
          if(inicio>3){texto=msg.substring(0, inicio);}else{texto='';}
          console.log(texto);
          resultado=true;
          EnviarTextoBotQR(texto+instruye);
      }else if (msg.indexOf("http") > -1)
      {   inicio=msg.indexOf("http");
          urlparamostrar = msg.substring(inicio);
          console.log(urlparamostrar);
          if(inicio>3){texto=msg.substring(0, inicio);}else{texto='';}
          console.log(texto);
          resultado=true;
          EnviarTextoBotQR(texto+instruye);          
      }else if (msg.indexOf("www") > -1)
      {   inicio=msg.indexOf("www");
          urlparamostrar = msg.substring(inicio+4);
          console.log(urlparamostrar);
          if(inicio>3){texto=msg.substring(0, inicio);}else{texto='';}
          console.log(texto);
          resultado=true;
          EnviarTextoBotQR(texto+instruye);

      }else if (msg.indexOf("menuopciones") > -1)
      {   inicio=msg.indexOf("menuopciones");
          //urlparamostrar = msg.substring(inicio);
          //console.log(urlparamostrar);
          if(inicio>3){texto=msg.substring(0, inicio);}else{texto='';}
          console.log(texto);
          resultado=true;
          EnviarTextoBotMenu(texto+instruyemenu); 

      }else if (msg.indexOf("video:") > -1) //Para mostrar un video en el popup (por ejemplo una publicidad)
      {   inicio=msg.indexOf("video:");
          urlparamostrar = msg.substring(inicio+6);
          if(inicio>3){texto=msg.substring(0, inicio-6);}
          jQuery('#multi').qrcode(texto);
          resultado=true;
      }else{
        EnviarTextoBot(texto);
      } 
      //console.log('SeparaUrl: Resultado '+resultado);
      //console.log('SeparaUrl: Texto '+texto);
      return resultado; //Si encontró url, devuelve true y urlparamostrar contiene la url separada
    }

  //Basada en Material
  function EsperaParaMostrarMenu2(mensaje)
    {
        setTimeout(function () 
            {
              console.log('A mostrar el Menu');
              var segundos=RetardoCerrarMenu + 1000; 
                var lap=setInterval(function(){ segundos=segundos-1; $('#segundosmenu').html(segundos+' seg');}, 1000);

                //$('#modal2').modal('open');
                $('#modal2').modal({
                  'onOpenEnd': initCarousel,
                  'opacity':	0.5,
                  'inDuration':	250,
                  'outDuration':	250,
                  'preventScrolling':	true,
                  'dismissible':	true,
                  'startingTop':	'4%',
                  'endingTop':	'10%'
                  });
                  
                  function initCarousel() {
                    $('.carousel').carousel({
                      fullWidth: false,     //Ancho completo
                      indicators: true,     //Puntos que indican cuantos son los botones
                      shift: 50,            //Espacio entre botones (superposición)
                      numVisible: 5,        //Elementos visibles
                      noWrap: false});      //Circular
                  }

                $('#modal2').modal('open');

            }, mensaje.length * delayAntesQR);
    }

  //Función vieja no utilizada. Solo para fines de copia del código  
  function ConstruirMenu2()
    { 
      var html='';
      var cantidad=0;
      var opciones=document.getElementById('opcionesmenu');
      var listado=document.createElement('ul');
      listado.setAttribute('style', 'text-align: center'); 
      listado.style.margin="0px";
      listado.style.position="fixed";
      //lnk=JSON.parse(data);
      for (var i = 0; i < MenuOpcionesMsg.OpcionesMenu.length; i++) {
        var opcion=MenuOpcionesMsg.OpcionesMenu[i].opcion;
        html=html+'<li>'+i+' - '+opcion+'"/></li>\n';
      }
      listado.innerHTML = html;
      opciones.appendChild(listado); 
      return true;
    }
  
  function EsperaParaMostrarQR(mensaje)
    {
        setTimeout(function () 
            {
              console.log('A mostrar el QR de la url');
              var segundos=RetardoCerrarQR; 
                var lap=setInterval(function(){ segundos=segundos-1; $('#segundospop').html(segundos+' seg');}, 1000);
                $('#popup').bPopup({
                    autoClose: segundos*1000, //Auto closes después de 
                    position: [100, 100], //x, y
                    speed: 650,
                    transition: 'slideIn',
                    transitionClose: 'slideBack',
                    onOpen: function() {
                        jQuery('#multi').qrcode(urlparamostrar);
                        $('#textomulti').html(urlparamostrar);
                        //contenidoPopup.html($('#btnPopUp').data('bpopup') || '');
                    },
                    onClose: function() {
                        //contenidoPopup.empty();
                        $('#multi').html('');
                        $('#textomulti').html('');
                        clearTimeout(lap);
                        asignaSR_QrCode(false);
                        SR_TerminaHabla(); //Vuelve a reconocimiento del habla
                    }
                });
            }, mensaje.length * delayAntesQR);
    }

  function AlmacenaPreguntayRespuesta()
    {
      try 
      {
        $.post(websiteTools+"AlmacenaPreguntaRespuesta.php", {usuario: UsrImagenesBD, pregunta : preguntausr, respuesta: respuestarobot}, 
          function(){})
          .fail(function() {});
      } catch (err) {console.log("Error al registrar pregunta y respuesta"); }
      return;
    }

  export function MuestraBot(accion)
    {
      switch(accion) {
          case 'Parpadea':
              $('#zonavideohabla').hide();
              $('#zonavideoparpadea').show();
              break;
          case 'Habla':
              $('#zonavideoparpadea').hide();
              $('#zonavideohabla').show();
              break;
          case 'Duerme':
              EnviarTextoBot(RespuestaAleatoria(HolaSiri));
              break;
          case 'Sonrie':
              EnviarTextoBot(RespuestaAleatoria(Abusivo));
              break;
          case 'Parpadea otra manera':
              Clima("Hoy");
              break;     
          default:
          }
    }
  
  /*
    Código qwe se ejecuta cuando el usuario dice algo
  */
  function EnviaTextoServerIot(mensaje) 
    {
      try {
        $.post(websiteIotText, {texto : mensaje}, 
          function(datarecibido){
                console.log("Recibido: "+datarecibido);
                if (messengerActivo===true) {
                  messenger.recieve(datarecibido);
                }else{
                  //EnviarTextoBot(String(datarecibido));  
                  SeparaUrl(String(datarecibido)); //PARA VER SI CONTIENE URL O VIDEO
                }
            })
          .fail(function()
            {console.log('Hubo un error');
            });
      } catch (err) {
        // No device was selected.
        console.log("Hubo un error al comunicarse con el servidor");
      }
  
      } //Fin EnviaTextoServerIot



  function NoticiaCliente() 
    { //Novedades o promociones del cliente tomadas desde la base de datos según su usuario
      try {
        $.post(websiteTools+"noticiascliente.php", {Usuario: UsrImagenesBD}, 
          function(Data){
                NewsData=JSON.parse(Data);
                console.info(NewsData);
                var Noticia="";
                var numero_elementos=NewsData.length;
                var numero=Math.floor(Math.random()*numero_elementos);
                var resumen=NewsData[numero].noticia;
                console.log(resumen);
                EnviarTextoBot(IntroNoticia() + resumen);
                return false;
            })
          .fail(function()
            { console.log('Hubo un error');
              EnviarTextoBot("No tengo novedades para contarle en este momento.");
              return false;
            });
       } catch (err) {
         // No device was selected.
         console.log("Hubo un error al comunicarse con el servidor");
         EnviarTextoBot("No tengo novedades para contarle en este momento.");
         return false;
       }
  
      } //Fin EnviaTextoServerIot

  function Clima(tipo) 
    { //Hoy | Pronostico
      try {
        $.post(climaLink, {"TipoClima" : tipo}, 
          function(Data){
                ClimaData=Data;
                console.info(ClimaData);
                if (tipo==="Hoy") 
                  {
                      var mensaje="";
                      mensaje="La temperatura actual es "+ClimaData[1].temperatura+" grados, ";
                      mensaje=mensaje+"con una humedad de "+ClimaData[1].humedad+" porciento. ";
                      mensaje=mensaje+ClimaData[2];
                      EnviarTextoBot(mensaje);
                      return false;
                  }
                else{
                  EnviarTextoBot("No tengo datos para los próximos días.");
                  return;
                }
            })
          .fail(function()
            { console.log('Hubo un error');
              EnviarTextoBot("No pude obtener información del clima. Disculpe.");
              return;
            });
       } catch (err) {
         // No device was selected.
         console.log("Hubo un error al comunicarse con el servidor");
         EnviarTextoBot("No pude obtener información del clima. Disculpe.");
         return;
       }
  
      } //Fin EnviaTextoServerIot

  function Noticia(tipo) 
    { //Hoy | Pronostico
      try {
        $.post(NewsLink, {"TipoNoticia" : tipo}, 
          function(Data){
                NewsData=Data;
                console.info(NewsData);
                if (tipo==="UltimoMomento") 
                  {
                      var Noticia=NoticiaAleatoria(NewsData);
                      var titulo=Noticia.titulo;
                      var resumen=Noticia.resumen;                      
                      EnviarTextoBot(IntroNoticia() + titulo + resumen);
                      return;
                  }
                else{
                  EnviarTextoBot("No tengo noticias en este momento.");
                  return;
                }
            })
          .fail(function()
            { console.log('Hubo un error');
              EnviarTextoBot("No tengo noticias en este momento.");
              return;
            });
       } catch (err) {
         // No device was selected.
         console.log("Hubo un error al comunicarse con el servidor");
         EnviarTextoBot("No tengo noticias en este momento.");
         return;
       }
  
      } //Fin EnviaTextoServerIot

  //Elige una noticia de un array aleatoriamente devolviendo título y descripcion en un json
  function NoticiaAleatoria(noticias)
    { 
      var Noticia="";
      var numero_elementos=noticias.length;
      var numero=Math.floor(Math.random()*numero_elementos);
      var titulo=noticias[numero].article.title;
      var resumen=noticias[numero].article.summary;
      Noticia={'titulo':titulo,'resumen':resumen};
      return Noticia;
    }    

  function IntroNoticia()
    { 
      var Prefacio=['Claro, ahi va una noticia: ',
                   'Bien, te cuento que ',
                   'Bueno, te comento esto: ',
                   'Sabías que ',
                   'Esta es la noticia: ',
                   'Noticia fresca: ',
                   'La novedad es que: ',
                   'Una noticia fresa es que '];      
      var numero_elementos=Prefacio.length;
      var numero=Math.floor(Math.random()*numero_elementos);
      var Intro=Prefacio[numero];
      return Intro;
    }    

  function Chiste() 
    { 
      try {
        $.post(ChistesLink, {} , 
          function(Data){
                ChistesData=Data;
                console.info(ChistesData);
                var numero_elementos=ChistesData.length;
                var numero=Math.floor(Math.random()*numero_elementos);
                var msgchiste=ChistesData[numero].chiste;
                console.log(msgchiste);
                //console.log(decodeURIComponent(msgchiste));
                EnviarTextoBot(msgchiste);
                return;
            })
          .fail(function()
            { console.log('Hubo un error');
              EnviarTextoBot("No tengo chistes para contarle en este momento.");
              return;
            });
       } catch (err) {
         // No device was selected.
         console.log("Hubo un error al comunicarse con el servidor");
         EnviarTextoBot("No tengo chistes para contarle en este momento.");
         return;
       }
  
      }




////////////////////////////////////////////////////////////////////////////////////////
/////////// FUNCIONES DE MESSENGER
////////////////////////////////////////////////////////////////////////////////////////

  function EntrarChat()
    {
        VerScreen("inicio", "chat");

        //Vacia el chat
        messageList = [];
        $('#content').html('');

        setTimeout(function() {
          messenger.recieve(SaludoHora(mensajeinicial1));
        }, 1500);
        
        setTimeout(function() {
          messenger.recieve(SaludoAleatorio(mensajeinicial2));
        }, 3000);
        
        setTimeout(function() {
          $input.focus();
        }, 3500);        
    }

  function SalirChat()
    {
        var t=setTimeout(function(){
          $('#chat').fadeOut('slow', function() {});
          $('#inicio').fadeIn('slow', function() {});
        },300);
    }

  function VerScreen(seccionborrar,seccionmostrar)
      {   //materialadmin.AppNavigation._handleMenuToggleClick();
          var t=setTimeout(function(){
            //Oculta la pantalla
          $('#' + seccionborrar).hide();
          //Muestra la pantalla solicitada
          $('#'+seccionmostrar).fadeIn('slow', function() {});
        },300);
      } 

  function safeText(text) 
    {
      $content.find('.message-wrapper').last().find('.text-wrapper').text(text);
    }
  
  function animateText() 
    {
      setTimeout(function() {
        $content.find('.message-wrapper').last().find('.text-wrapper').addClass('animated fadeIn');
      }, 350);
    }
    
  function MessengerScrollBottom() 
    {
      $($inner).animate({
        scrollTop: $($content).offset().top + $($content).outerHeight(true)
      }, {
        queue: false,
        duration: 'ease'
      });
    }
    
  function MessengerBuildSent(message) 
    {
      console.log('sending: ', message.text);
      //EnviaTextoServerIot(message);
      $content.append(buildHTML.me(message.text));
      safeText(message.text);
      animateText();
      
      MessengerScrollBottom();
    }
  
  function MessengerBuildRecieved(message) 
    {
      console.log('recieving: ', message.text);
      
      $content.append(buildHTML.them(message.text));
      safeText(message.text);
      animateText();
      
      MessengerScrollBottom();
    }
  
  function MessengerSendMessage() 
    {
      text = $input.val();
      console.log("text: "+text);
      //ENVIAR MENSAJE POR AJAX
      EnviaTextoServerIot(text);
      messenger.send(text);
      
      $input.val('');
      $input.focus();
    }

///////////////////////////////////////////////////////////////////////////////////////
//////////// FUNCIONES DE TRADUCCION
///////////////////////////////////////////////////////////////////////////////////////
  //Se aplica cuando se completó una frase desde SR_recognition.onresult
  /* 
    var IdiomaOperadorDefault="es";
    var IdiomaDisertanteDefault="es";
  */
  function TraducirYHablar(tex, deIdioma='es', aIdioma='en') 
  {
    console.log('TraducirYHablar: '+tex, deIdioma, aIdioma);
    if (tex===''){return;}  //Evita errores en la API de traducción      
    agregarHistorialMensajes(tex, 'user');
    verGrafico("pensando");
    TraducirAPIGoogle(tex, deIdioma, aIdioma).then(function(msg_traducido,error){
      console.log(msg_traducido, error);                  
      //MENSAJE YA TRADUCIDO
      switch (config.MorotIA) {
        case 'Groq':
          EnviaTextoServerGROQ(msg_traducido);
          console.log("Enviando a GROQ");
          break;
        case 'GroqLocal':
          EnviaTextoServerGROQ(msg_traducido);
          console.log("Enviando a GROQ");
          break;
        case 'LM Studio':
          EnviaTextoServerLMStudio(msg_traducido);
          console.log("Enviando a LM Studio");
          break;
        default:
          alert('No se ha seleccionado un motor de IA en la configuración');
          break;
      }
      
    },
    function(){
      console.log("Error en el Promise");
    });        

  } //Fin Traductor Local

  ///////////////////////////////////////////////////////////////////////
  ///  API DE GOOGLE PARA TRADUCIR EN TIEMPO REAL Y ENVIAR AL MOTOR IA //
  ///////////////////////////////////////////////////////////////////////
    /*
    Código que se ejecuta cuando el usuario dice algo
  */
    let isFirstCall = true; // Initialize flag for first call to the Motor IA rol

    ///////////////////////////////////////////////////////////////
    ////  Llamada para LM Studio en modo 100% local
    ///////////////////////////////////////////////////////////////
    async function EnviaTextoServerLMStudio(mensaje) {
      if (isFirstCall) {
        //mensaje="Introduce yourself briefly, in 4 phrases, stating your role and capabilities within that role. Your responses should be articulate and to the point, providing clear and concise information. Eventually, randomly, make a greeting according to the time of day.";
        //mensaje=rol_default;        
        console.log("Primer mensaje: "+mensaje);
      }
      if (config.limitaLargoRespuesta === 'Si') {
        mensaje = "Limits your answer to " + config.largoRespuesta + " characters." + mensaje;
      }
      try {
        const url = config.urlMotorIA;  // Replace with actual URL if not local
        const data = {
          messages: [
            { role: "user", content: mensaje }
          ],   
          temperature: 0.7,
          max_tokens: -1,
          stream: false,
        };

        console.log(truncateRole(rol_default));
        // Add system message only on the first call
        if (isFirstCall) {
          data.messages.unshift({
            role: "system",
            content: truncateRole(rol_default)
          }); 
          isFirstCall = false; // Set flag to false for subsequent calls
        }
    
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
    
        const response = await fetch(url, options);
    
        if (!response.ok) {
          throw new Error(`Error sending message: ${response.statusText}`);
        }
    
        const datarecibido = await response.json();
        console.log("Recibido:", datarecibido);
        // Extract content from the first choice
        const content = datarecibido?.choices?.[0]?.message?.content;
  
        if (content) {
          //console.log("Extracted content:", content);
          TraducirAPIGoogle(content, 'en', 'es').then(function(msg_traducido,error){
            console.log(msg_traducido);
            //MENSAJE TRADUCIDO a español
            agregarHistorialMensajes(msg_traducido, 'assistant');

            if (messengerActivo === true) {
              messenger.receive(msg_traducido); // Assuming messenger receives text content
            } else {
              // Implement if needed
              SeparaUrl(String(msg_traducido)); // Assuming this handles URLs/videos
            }
          },
          function(){
            console.log("Error en el Promise");
          }); 

        } else {
          console.error("No content found in the response");
        }
  
      } catch (err) {
        console.error("Error:", err.message);
        mostrarPopup('Parece que no tiene activo el servidor LM Studio. Por favor, verifique la configuración.');
      }
    }

    ///////////////////////////////////////////////////////////////
    ////  Llamada para GROK con llamadas API
    ///////////////////////////////////////////////////////////////    

    async function EnviaTextoServerGROQ(mensaje) {
      if (isFirstCall) {
        //mensaje="Introduce yourself briefly, in 4 phrases, stating your role and capabilities within that role. Your responses should be articulate and to the point, providing clear and concise information. Eventually, randomly, make a greeting according to the time of day.";
        mensaje=rol_default;
        console.log("Primer mensaje: "+mensaje);
      }
      if (config.limitaLargoRespuesta === 'Si') {
        mensaje = "Limits your answer to " + config.largoRespuesta + " characters." + mensaje;
      }
      try {
        const url = config.urlMotorIA;  // Replace with actual URL if not local
        //{ role: "system", content: "Always answer in rhymes." },
        //{ role: "system", content: "Always answer like a psychologist." },

        const data = {
          messages: [
                ...conversationHistory,
                // Set an optional system message. This sets the behavior of the
                // assistant and can be used to provide specific instructions for
                // how it should behave throughout the conversation.
                // {
                //     role: "system",
                //     content: rol_empatico
                // },
                // Set a user message for the assistant to respond to.
                {
                    role: "user",
                    content: mensaje //+ config.largoRespuesta
                }                
              ], 
              // The language model which will generate the completion.
              model: config.groq.model,
              //
              // Optional parameters
              //
              // Controls randomness: lowering results in less random completions.
              // As the temperature approaches zero, the model will become deterministic
              // and repetitive.
              temperature: config.groq.temperature,
              // The maximum number of tokens to generate. Requests can use up to
              // 2048 tokens shared between prompt and completion.
              max_tokens: config.groq.max_tokens,
              // Controls diversity via nucleus sampling: 0.5 means half of all
              // likelihood-weighted options are considered.
              top_p: config.groq.top_p,
              // A stop sequence is a predefined or user-specified text string that
              // signals an AI to stop generating content, ensuring its responses
              // remain focused and concise. Examples include punctuation marks and
              // markers like "[end]".
              stop: config.groq.stop,
              // If set, partial message deltas will be sent.
              stream: config.groq.stream
        };

        // Add system message only on the first call
        if (isFirstCall) {
          /* data.messages.unshift({
            role: "system",
            content: rol_default
          }); */
          isFirstCall = false; // Set flag to false for subsequent calls
        }
        /* console.log("Mensaje acumulado: ");
        console.log("----------------------------------------------");
        console.log(data);
        console.log("----------------------------------------------"); */
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
          //mode: 'no-cors', // Cambia el modo para evitar el bloqueo de CORS
        };
    
        const response = await fetch(url, options);
    
        if (!response.ok) {
          throw new Error(`Error sending message: ${response.statusText}`);
        }
    
        const datarecibido = await response.json();
        console.log("Recibido:", datarecibido);
        // Extract content from the first choice
        const content = datarecibido?.choices?.[0]?.message?.content;
  
        if (content) {
          //console.log("Extracted content:", content);
          TraducirAPIGoogle(content, 'en', 'es').then(function(msg_traducido,error){
            console.log(msg_traducido);
            //MENSAJE TRADUCIDO a español
            agregarHistorialMensajes(msg_traducido, 'assistant');
            if (messengerActivo === true) {
              messenger.receive(msg_traducido); // Assuming messenger receives text content
            } else {
              // Implement if needed
              SeparaUrl(String(msg_traducido)); // Assuming this handles URLs/videos
            }
          },
          function(){
            console.log("Error en el Promise");
          }); 

        } else {
          console.error("No content found in the response");
        }
  
      } catch (err) {
        console.error("Error:", err.message);
        mostrarPopup('Parece que no tiene activo el servidor API de groq. Por favor, verifique la configuración.');
      }
    }
      


///////////////////////////////////////////////////////////////
////  Función de traducción mediante las API de google
///////////////////////////////////////////////////////////////    

  function TraducirAPIGoogle(texto, deIdioma, aIdioma)
  {  console.log('TraducirAPIGoogle: '+texto, deIdioma, aIdioma);
    return new Promise(function(resolve, reject) {
      if (deIdioma === aIdioma || texto==='') { //Inicio de idiomas iguales o texto es nulo
        TextoTraducido=texto;
        console.log("Idiomas iguales o texto nulo");
        resolve(TextoTraducido);  //RETORNO CORRECTO. Son iguales los idiomas
      }else{
        var translate_api_json = 
        {
          'q': texto,
          'source': deIdioma,
          'target': aIdioma,
          'format': 'text'
        };

        var file_contents = JSON.stringify(translate_api_json);
        console.log("Hasta acá tiene el json a enviar");

        //con XML
        console.log("Con XML");
        var xhr = new XMLHttpRequest();
        const apiKey = process.env.API_KEY;
        xhr.open('POST', config.servertranslate + apiKey, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function ()
        {
          console.log(this.responseText);
          var res=JSON.parse(this.responseText);        
          let TextoTraducido=res.data.translations[0].translatedText;
          resolve(TextoTraducido);  //RETORNO CORRECTO
        };
        xhr.send(file_contents);
        console.log(xhr.status);
        if (xhr.status!==0 ) {
          Materialize.toast('Without internet connection', 3000, 'rounded red');
          reject(new Error('Error. Parece que no hay internet.')); //RETORNO ERROR
        }
      } // Fin de idiomas iguales
    }); //Fin de la promesa
  }

  //Función que permite definir un rol inicialmente para el robot al comenzar el funcionamiento
  //Seleccionando de la lista los posibles roles
  export function asignarRol(rol) {
    // Eliminar la clase "active" del elemento seleccionado anteriormente
    var elementos = document.getElementsByClassName("collection-item active");
    if (elementos.length > 0) {
      elementos[0].classList.remove("active");
    }
  
    // Agregar la clase "active" al nuevo elemento seleccionado
    var nuevoElemento = document.getElementById(rol);
    nuevoElemento.classList.add("active");
  
    // Access role description from the roles object
    rol_default = config.roles[rol];
  
    // Mostrar una descripción del rol seleccionado
    var descripcion = document.getElementById("descripcion-rol");
    //descripcion.innerHTML = "El rol " + rol + " se encarga de ...";
    descripcion.innerHTML = "Rol: " + config.describe[rol];
  
    // Cambiar el color del fondo del chatbot
    // var chatbot = document.getElementById("chatbot");
    // chatbot.style.backgroundColor = "color-asociado-al-rol";
  }
  

  //funcion que permite mostrar un mensaje en un popup usando la librería jquery.bpopup
  function mostrarPopup(mensaje) {
    $('#contenido_mensaje_popup').text(mensaje);
    $('#popupmensaje').bPopup({
      contentContainer: '.contenido',
      //loadUrl: 'test.html' // Opcional: Cargar contenido desde una URL
    });
  }

  // Cerrar la ventana emergente al hacer clic en el botón
  $('#boton-cerrarpopup').click(function() {
    $('#popupmensaje').bPopup().close();
  });
  
  //funcion que permite mostrar un caso solicitado por el usuario al robot
  function mostrarPopupCaso(mensaje) {
    $('#contenido_mensaje_popupejemplo').text(mensaje);
    $('#popupejemplo').bPopup({
      contentContainer: '.contenido',
      //loadUrl: 'test.html' // Opcional: Cargar contenido desde una URL
    });
  }

  // Cerrar la ventana emergente al hacer clic en el botón
  $('#boton-cerrar_popupejemplo').click(function() {
    $('#popupejemplo').bPopup().close();
    EnviarTextoBot('Bien, continuemos');
  });  

  const conversationHistory = [];
  //funcion que agrega un mensaje al historial de conversación
  function agregarHistorialMensajes(mensaje, rol) {
    //rol {'user', 'assistant'}
    conversationHistory.push({ role: rol, content: mensaje });
  }


  //funcion que permite exportar el historial de conversación a word
  function exportarHistorialConversacion() {
    // Crear un string para almacenar la conversación
    let conversacionTexto = "";
  
    // Agregar los mensajes al string
    conversationHistory.forEach((mensaje, index) => {
      const { role, content } = mensaje;
      const texto = `${role}: ${content}\n`; // Agregar un salto de línea al final de cada mensaje
      conversacionTexto += texto;
    });
  
    // Crear un blob de texto
    const blob = new Blob([conversacionTexto], { type: "text/plain" });
  
    // Guardar el archivo como texto plano
    saveAs(blob, "historial-conversacion.txt");
    console.log("Conversación guardada como texto plano");
  }

  function muestraultimomensaje() {
    // Obtener el último mensaje del historial de conversación
    const ultimoMensaje = conversationHistory[conversationHistory.length - 1];
  
    // Mostrar el último mensaje en la consola
    console.log(ultimoMensaje);
    mostrarPopupCaso(ultimoMensaje.content);
  }

  //funcion que corta la descripción de un rol para enviarla a LM Studio, ya que el contexto parece afectarlo
  function truncateRole(roleString) {
    const dotIndex = roleString.indexOf(".");
    if (dotIndex === -1) {
      return roleString;
    }
    return roleString.slice(0, dotIndex);
  }

  export function verGrafico(id) 
	{
		$('#micescuchando').hide();
		$('#barras').hide();
		$('#micbloqueado').hide();
    $('#pensando').hide();
		if (id!=="") {  $('#'+id).show(); }
	}
