export const menu1=
    {
        'CantidadOpciones':5,
        //{"opcion":'',"accion":'',"confirma":''}
        'OpcionesMenu':[
            'Si',
            'No',
            'Tal vez',
            'Cerrar esto',
            'Continuar con otra cosa',
            ],
        "MensajesAsociados":[
            'Ha respondido afirmativamente.',
            'Ha elegido la opción No.',
            'Su eleción fué tal vez.',
            'Voy a cerrar esto sin hacer nada.',
            'Voy a continuar con otra cosa entonces, gracias',
            ],
        // 'DiccionarioRespuestas':[       //ya no sería necesario porque será tactil
        //         {'opcion':'1', 'valor':'si'},
        //         {'opcion':'1', 'valor':'afirmativo'},
        //         {'opcion':'2', 'valor':'no'},
        //         {'opcion':'3', 'valor':'tal vez'},
        //         {'opcion':'3', 'valor':'quizas'},
        //         {'opcion':'3', 'valor':'quizás'},
        //         {'opcion':'4', 'valor':'cerrar'},
        //         {'opcion':'5', 'valor':'cerrar'}
        //     ],
        'Acciones':[
                'cerrar',
                'cerrar',
                'Confirmar',
                'cerrar',
                'Confirmar'
            ]
    };