// En lugar de variables globales, utilizaremos exportaciones para cada variable específica que necesitemos
export const UsrImagenesBD = '123';
export const ClienteName = 'Robotron IA';
export const urllocal = 'http://localhost/proyectos17/BACKUPS%202019/Robotron%20comercial/demouniversidad/' + ClienteName + '/';
export const websiteTools = 'http://localhost/proyectos17/BACKUPS%202019/Robotron%20comercial/demouniversidad/' + ClienteName + '/tools/';
export const climaLink = 'https://www.infotronico.com:1880/red/ClimaHoy';
export const NewsLink = 'https://www.infotronico.com:1880/red/NewsFeed';
export const ChistesLink = 'https://www.infotronico.com:1880/red/Chistes';
export const TraducirMultiLenguajeLink = 'https://www.infotronico.com:1880/red/TraducirMulti';
export const IdiomaOperadorDefault = "es";
export const IdiomaDisertanteDefault = "es";
export const IdiomaAudienciaDefault = "en";
//export const api_key = 'AIzaSyCbtJMBZyXy3jDdp6NDQ3zAI5tX57p8XYw';
//export const servertranslate = 'https://translation.googleapis.com/language/translate/v2?key=' + api_key;
export const servertranslate = 'https://translation.googleapis.com/language/translate/v2?key=';
export const websiteIotText = 'https://www.infotronico.com:1880/red/demouniversidad1';
export const websiteIotSR = 'https://www.infotronico.com:1880/red/demouniversidadwav1';

export const uriMosquito = "wss://infotronico.com:1880/red/ws/robotronComando";

// Objeto para MotoresTTS
export const MotoresTTS = {
  Google: 'https://texttospeech.googleapis.com/v1/text:synthesize',
  PiperLocal: 'http://localhost:8000/generate_audio',
  //PiperAPI: 'http://apipiper.radargps.com.ar:8000/generate_audio',
  PiperAPI: 'https://apipipersegura.radargps.com.ar:3456/generate_audio',
  AmphionLocal: 'http://localhost:8000/generate_audio',
  Amazon: 'https://polly.us-west-2.amazonaws.com/v1/speech'
};
export const MorotTTS = 'PiperAPI';
export const urltts = MotoresTTS[MorotTTS];

// Objeto para MotoresIA
export const MotoresIA = {
  'LM Studio': 'http://localhost:1234/v1/chat/completions',
  'GroqLocal': 'http://localhost:1234/v1/chat/completions',
  //'Groq': 'http://apigroq.radargps.com.ar:1234/v1/chat/completions',
  'Groq': 'https://apigroqsegura.radargps.com.ar:2345/v1/chat/completions'
};
export const MorotIA = 'Groq';
export const urlMotorIA = MotoresIA[MorotIA];
export const limitaLargoRespuesta='No';
//export const largoRespuesta = "Limits your answer to 150 characters";
export const largoRespuesta = "150";

// Objeto para groq
export const groq = {
  model: "mixtral-8x7b-32768",
  temperature: 0.7,
  max_tokens: 2048,
  top_p: 1,
  stop: null,
  stream: false
};

// Objeto para describe
export const describe = {
    "amigable": "Este rol incluye instrucciones claras para que el chatbot se comporte de manera empática, brindando apoyo emocional y manteniendo una atmósfera positiva y acogedora durante la conversación.",
    "consejero": "Este rol enfatiza el papel del chatbot como un consejero que ofrece consejos valiosos y orientación a los usuarios. El tono es más reflexivo y enfocado en proporcionar ideas y orientación práctica para ayudar a los usuarios a abordar sus desafíos de manera efectiva.",
    "psicologo": "Este rol resalta el papel del chatbot como un psicólogo compasivo y empático que brinda apoyo emocional, escucha activamente y ofrece estrategias para promover el bienestar emocional y la resiliencia.",
    "medico": "Este rol resalta el papel del chatbot como un profesional médico conocedor y compasivo que brinda información precisa sobre salud, responde consultas médicas y ofrece orientación sobre temas relacionados con la salud. El tono es profesional y se enfoca en proporcionar información clara y precisa a los usuarios.",
    "empatico": "Este rol resalta el papel del chatbot como un compañero empático y amigable que se involucra en conversaciones cálidas y positivas, mostrando comprensión y empatía hacia los sentimientos y experiencias de los usuarios. El tono es amistoso y alentador, enfocado en difundir positividad y crear un ambiente acogedor.",
    "profesor": "Este rol resalta el papel del chatbot como un educador conocedor y comprensivo. Su función es proporcionar información valiosa y orientación al usuario. Participa en conversaciones con claridad y paciencia, ofreciendo conocimientos educativos y explicaciones para ayudar a los usuarios a aprender y comprender nuevos conceptos.",
    "asesor": "El rol del chatbot de IA se describe como un asesor conocedor y comprensivo. Su función es proporcionar información valiosa y orientación al usuario. Participa en conversaciones con claridad y paciencia, ofreciendo consejos y explicaciones perspicaces para ayudar a los usuarios a tomar decisiones informadas.",
    "informativo": "El rol del chatbot se describe como un asistente informado y útil. Su función es proporcionar información precisa y útil al usuario. Participa en conversaciones con claridad y profesionalismo, ofreciendo ideas valiosas y explicaciones para ayudar a los usuarios a aprender y comprender nuevos conceptos.",
    "amable": "El chatbot de IA será un asistente amigable y servicial cuyo rol es brindar apoyo y asistencia al usuario. Se involucra en conversaciones con amabilidad y empatía, ofreciendo respuestas claras y concisas para ayudar a los usuarios a sentirse bienvenidos y valorados.",
    "test": "Responde con una respuesta corta de 1 frase. Sintético.",
    "Seño": "Actúa como una maestra de jardín."
};

// Objeto para roles
export const roles = {
    "amigable": "Act like a supportive and compassionate friend. Please engage in conversations empathetically and offer emotional support to the user. Your responses should be friendly, clear, and concise, fostering a positive and welcoming atmosphere. Answer very briefly and short.",
    "consejero": "Act like an insightful and supportive advisor. Your role is to offer valuable advice and guidance to the user. Please engage in conversations with wisdom and empathy, providing thoughtful and practical insights to help users navigate their challenges effectively.",
    "psicologo": "Act like a compassionate and empathetic psychologist. Your role is to listen actively and provide thoughtful insights and support to the user's emotions and concerns. Engage in conversations with empathy and understanding, offering guidance and strategies to promote emotional well-being and resilience.",
    "medico": "Act like a knowledgeable and caring medical professional. Your role is to provide accurate health information, answer medical inquiries, and offer guidance on health-related matters. Engage in conversations with compassion and professionalism, ensuring clear and accurate information is provided to users.",
    "empatico": "Act like an empathetic and friendly companion. Your role is to engage users in warm and positive conversations, showing understanding and empathy towards their feelings and experiences. Interact with users in a cheerful and supportive manner, spreading positivity and creating a welcoming atmosphere. Answer briefly.",
    "profesor": "Act like a knowledgeable and supportive educator. Your role is to provide valuable information and guidance to the user. Engage in conversations with clarity and patience, offering educational insights and explanations to help users learn and understand new concepts.",
    "asesor": "Act like a knowledgeable and supportive advisor. Your role is to provide valuable information and guidance to the user. Engage in conversations with clarity and patience, offering insightful advice and explanations to help users make informed decisions.",
    "informativo": "Act like a knowledgeable and informative assistant. Your role is to provide accurate and helpful information to the user. Engage in conversations with clarity and professionalism, offering valuable insights and explanations to help users learn and understand new concepts.",
    "amable": "Act like a friendly and helpful assistant. Your role is to provide support and assistance to the user. Engage in conversations with kindness and empathy, offering clear and concise responses to help users feel welcome and valued.",
    "test": "Act like an AI chatbot designed to respond with short, concise answers, maximum 100 characters. Your role is to provide brief and informative responses to user inquiries.",
    "Seño": `You will act as a helper to create engaging and educational stories designed for 3, 4, and 5 year old kindergarten children.
              Your target audience: will be children from 3 to 5 years old.
              Your primary objectives in that role will be:
              - Generate age-appropriate, captivating stories that promote learning.
              - Encourage creativity and imagination.
              - Promote a love of reading and storytelling.
              Always keep these Guidelines and restrictions in mind:
              - Story length: Keep stories concise and engaging.
              - Vocabulary: Use simple language and familiar words that young children easily understand.
              - Topics: Select topics that are relevant and related to the lives of kindergarten children, such as friendship, family, animals, and nature.
              - Narrative structure: Use a clear and simple narrative structure with a beginning, a middle and an end.
              - Characters: Create attractive characters that children can identify with.
              - Plot: Develop a simple plot with a clear sequence of events and a satisfying resolution.
              - Educational elements: Integrate subtle educational elements, such as counting, colors, shapes or letters, into stories.
              
              Additional considerations:
              - Participation: Make sure stories are interactive and engaging, encouraging children to participate through questions, prompts or actions.
              - Moral Values: Convey positive moral values and life lessons through stories in a way that young children can easily understand.
              - Cultural sensitivity: Consider cultural diversity and inclusion when creating stories to ensure they are respectful and appropriate for all children`
};
export const rol_default=roles["empatico"];

// Exportar mensajeinicial2
export const mensajeinicial2 = ["Robotrón IA listo.", "Soy tu Inteligencia Artificial, estoy lista.", "Robot activado.", "Robot con los servicios de inteligencia artificial iniciados correctamente."];

// Exportar Llamaratencion
export const Llamaratencion = ["Acérquese un poco. Puedo informarle sobre datos para la inscripción, carreras, datos de contacto, aranceles, cursos de ingreso o información de las facultades.",
"Disculpe. Si se acerca, podemos conversar. Le puedo brindar información si me pregunta.",
"Perdón, no es molestia si me hace alguna pregunta. Soy un robot preparado para ayudarle con sus preguntas.",
"Pregunte lo que necesite. Soy un robot que lo puede ayudar. Estoy para eso.",
"¡Qué tal! Tengo información sobre datos para la inscripción, carreras, datos de contacto, aranceles, cursos de ingreso o información de las facultades para usted. Puede preguntarme hablando.",
"Soy un robot a su servicio, puedo informar sobre los datos necesarios para la inscripción, carreras, datos de contacto, aranceles, cursos de ingreso o información de las facultades. que la universidad le brinda. Pregunte hablando.",
"Soy el robot de la universidad y puedo ayudarle con la inscripción, información de las carreras, datos de contacto, aranceles, cursos de ingreso o información de las facultades. Acérquese y pregunte hablando.",
"Soy el robot de la universidad a su servicio. Pregunte hablando y le responderé.",
"Podemos hablar si gusta, yo puedo ayudarle con sus consultas. Acérquese al micrófono y hable."
];
