import  * as config  from './config/newConfigLocal.js';
import { MuestraBot, verGrafico } from './robotronRobotinterim.js';
import { asignaSR_HablandoAhora, asignaSR_ProcesandoReconocimiento, SR_recognition } from './sdkSR.js';

// "DemoUniversidad Robotrón v1.1"
//Configuración default para speak
var spkvoice = "cmu-slt";
var spkvoiceMod = "default";
var spknativeVoice = true;
var spklang = "es_AR";
var spknativeVoiceName = "Microsoft Helena Desktop - Spanish (Spain)";

var spkspeechRate=1.2;
var spkspeechPitch=1;

var speechSynthesis = 'speechSynthesis' in window;
let SR_QrCode = false;
export function asignaSR_QrCode(status){
	SR_QrCode = status;
};

export function SR_TerminaHabla()
	{   //Habilita la escucha del robot de lo que hable el usuario en microfono
		console.log("SR_TerminaHabla - QRCode="+SR_QrCode);     
		if (SR_QrCode===true) 
			{
				console.log("SR_TerminaHabla sin startSpeechRecognition");
				return;
			}
		else{           
				try {
					SR_recognition.start();
					console.log("SR_recognition.start() OK");               
					//SR_recognitionActive = true;
				}
				catch(err) {
					console.log("SR_recognition.start() Error: "+err.error);
				}
				finally {
					asignaSR_HablandoAhora(false);                 //Las dos variables que gestionan el control del robot, SR y habla
					asignaSR_ProcesandoReconocimiento(false);          //
					verGrafico('micescuchando');
				}
			//SR_recognitionActive=false;

			return;
			//AlmacenaPreguntayRespuesta();
		}
	}
 
//////////////////////////////////////////////////////////////
//PROCESAMIENTO DE FRASES, TTS Y AUDIO NATURAL
//////////////////////////////////////////////////////////////
//Apila el procesamiento de frases, TTS y audio natural
// Función principal:
export function tts(paragraph){
    processParagraph(paragraph)
        .then(() => {
            MuestraBot('Parpadea');         //REVISAR SI HACE FALTA
            SR_TerminaHabla();
            console.log("Procesamiento completado para todas las frases.");
        })
        .catch((error) => {
            console.error(`Error procesando frases: ${error}`);
        });
}
async function processParagraph(paragraph) {
	paragraph=detectAndRemoveEmojis(paragraph);
	const sentences = paragraph.split(/[.!?]/).map(sentence => sentence.trim()).filter(sentence => sentence !== '');
	const audioPromises = []; // Array to store promises for audio playback
  
	for (let i = 0; i < sentences.length; i++) {
		const sentence = sentences[i];
		try {
			const ttsPromise = await convertToTTS(sentence); // Start TTS conversion asynchronously
			const audioPromise = playAudioFromTTS(ttsPromise); // Start audio playback asynchronously from TTS promise
			audioPromises.push(audioPromise); // Store the audio playback promise
			console.log(`Conversion and playback started for "${sentence}"`);
		} catch (error) {
			console.error(`Error processing sentence "${sentence}": ${error}`);
		}
	}
	// Wait for all audio playback promises to resolve
	await Promise.all(audioPromises);
	console.log("Processing completed for all sentences.");
  }

  // Helper function to play audio from TTS promise
async function playAudioFromTTS(ttsPromise) {
	try {
		const audioUrl = await ttsPromise; // Wait for TTS conversion to complete and get audio URL
		await playAudio(audioUrl); // Start audio playback asynchronously
	} catch (error) {
		console.error(`Error playing audio: ${error}`);
	}
  }

// Function to call REST API for TTS conversion
async function convertToTTS(sentence) {
    // API call or use an actual API to convert text to speech
    return new Promise((resolve, reject) => {
        console.log("API TTS: "+ sentence);
        fetch(config.urltts, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ text: sentence })
        })
        .then(response => response.blob())
        .then(blob => {
            const url = URL.createObjectURL(blob);			
			console.log('Fin proceso TTS frase: '+ sentence);
            resolve(url);
        })
        .catch(error => {
            console.log('Error:', error);
            reject(error);
        });
    });
  }

// Array para almacenar la cola de reproducción
const audioQueue = [];
let isPlaying = false; // Bandera para controlar si hay audio en reproducción

// Función para crear la lista de reproducción y reproducir audio secuencialmente
async function playAudio(audioUrl) {
	audioQueue.push(audioUrl); // Encolar la URL del audio
	if (!isPlaying) {
		isPlaying = true; // Marcar que hay audio en reproducción
		while (audioQueue.length > 0) {
			const nextAudioUrl = audioQueue.shift(); // Obtener el siguiente audio de la cola
			try {
				await playSingleAudio(nextAudioUrl); // Reproducir el audio				
			} catch (error) {
				console.error(`Error playing audio: ${error}`);
			}
		}
		isPlaying = false; // No hay audio en reproducción
		MuestraBot('Parpadea');
	}
  }
  
  // Función para reproducir un solo audio desde la cola
function playSingleAudio(audioUrl) {
	return new Promise((resolve) => {
		const audio = new Audio(audioUrl);
		audio.onended = () => {
			resolve(); // Resuelve la promesa cuando termina la reproducción
		};
		MuestraBot('Habla');
		audio.play();
	});
  }

// Define un objeto con datos de emojis
const emojisData = {
	'\u{1F600}': 'cara sonriente',
	'\u{1F603}': 'cara feliz con ojos sonrientes',
	'\u{1F604}': 'cara sonriente con ojos sonrientes',
	'\u{1F642}': 'cara con lágrimas de alegría',
	'\u{1F601}': 'cara sonriente y entrecerrada',
	'\u{1F970}': 'deslumbrado',
	'\u{1F606}': 'cara sonriente con sudor',
	'\u{1F605}': 'cara sonriente con ojos sonrientes y sudor',
	// Agrega más emojis felices aquí
  };
  
  // Define una función para detectar y remover emojis de un texto
function detectAndRemoveEmojis(text) {
	const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/ug;
	const emojisFound = text.match(emojiRegex); // Encuentra todos los emojis en el texto
	if (!emojisFound) {
		return text; // No se encontraron emojis, devuelve el texto original
	}
  
	let cleanedText = text;
	emojisFound.forEach(emoji => {
		if (emojisData[emoji]) {
			// Si el emoji está en el objeto de datos, lo reemplaza con un espacio en blanco
			cleanedText = cleanedText.replace(new RegExp(emoji, 'gu'), '');
		}
	});

	return cleanedText.trim();
  }

/////////////////////////////////////////////////////////////////////
//FIN PROCESAMIENTO DE FRASES, TTS Y AUDIO NATURAL NUEVA VERSIÓN
/////////////////////////////////////////////////////////////////////

function ttsNativo(text, voice, native, lang, nativeVoice) 
	{	
		if (typeof voice === "undefined") { voice=spkvoice; }
		if (typeof native === "undefined") { native=spknativeVoice; }
		if (typeof lang === "undefined") { lang=spklang; }
		if (typeof nativeVoice === "undefined") { nativeVoice=spknativeVoiceName; }
		console.log(voice + ' ' + native + ' ' + lang + ' ' + nativeVoice);
		//try {
			//if ((native || (native == null && voice == null)) && SDK.speechSynthesis) {
				if ('SpeechSynthesisUtterance' in window) {
					utterance = new SpeechSynthesisUtterance(text);
				} else {
					utterance = new SpeechSynthesisUtterance(text);
					console.log("Utterance2");
				}
				nativeTTS(utterance, lang, nativeVoice);		
		// } catch (error) {
		// 	console.log('Error: Speech web request failed');
		// }
	}

// *
//  * Speak the native utterance first setting the voice and language.
 
function nativeTTS(utterance, lang, voice) 
	{
		MuestraBot('Habla');
		if (spkspeechRate !== null) {
			utterance.rate = spkspeechRate;
		}
		if (spkspeechPitch !== null) {
			utterance.pitch = spkspeechPitch;
		}
		if (lang === null) {
			lang = 'es-AR';
		}
		
		utterance.addEventListener("end", function() {
				MuestraBot('Parpadea');
				console.log("Terminó de hablar.");
				SR_TerminaHabla();
			});

		speechSynthesis.cancel();
		if (lang === null && voice === null) {
			// Events don't always get fired unless this is done...
			setTimeout(function() {
				speechSynthesis.speak(utterance);
			}, 100);
			return;
		}
		var voices = speechSynthesis.getVoices();
		var foundVoice = null;
		var foundLang = null;
		var spoken = false;
		// speechSynthesis.getVoices().forEach(function(voice) {
		// 	console.log(voice.name, voice.default ? voice.default :'');
		// });
		if (voices.length === 0) {
			speechSynthesis.onvoiceschanged = function() {
				if (spoken) {
					return;
				}
				voices = speechSynthesis.getVoices();
				for (i = 0; i < voices.length; i++) {
					if (voice !== null && voice.length !== 0 && voices[i].name.toLowerCase().indexOf(voice.toLowerCase()) !== -1) {
						if (foundVoice === null || voices[i].name === voice) {
							foundVoice = voices[i];
						}
					} else if (lang !== null && lang.length !== 0 && voices[i].lang.toLowerCase().indexOf(lang.toLowerCase()) !== -1) {
						if (foundLang === null || voices[i].lang === lang) {
							foundLang = voices[i];
						}
					}
				}
				if (foundVoice !== null) {
					utterance.voice = foundVoice;
				} else if (foundLang !== null) {
					utterance.voice = foundLang;
				}
				spoken = true;
				setTimeout(function() {
					speechSynthesis.speak(utterance);
				},100);
			};
		} else {
			for (i = 0; i < voices.length; i++) {
				if (voice !== null && voice.length !== 0 && voices[i].name.toLowerCase().indexOf(voice.toLowerCase()) !== -1) {
					if (foundVoice !== null || voices[i].name === voice) {
						foundVoice = voices[i];
					}
				} else if (lang !== null && lang.length !== 0 && voices[i].lang.toLowerCase().indexOf(lang.toLowerCase()) !== -1) {
					if (foundLang === null || voices[i].lang === lang) {
						foundLang = voices[i];
					}
				}
			}
			if (foundVoice !== null) {
				utterance.voice = foundVoice;
			} else if (foundLang !== null) {
				utterance.voice = foundLang;
			}
			setTimeout(function() {
				speechSynthesis.speak(utterance);
			},100);
		}
	}


