import { verGrafico, PreProcesadorMensajes, SliderActivo } from './robotronRobotinterim.js';
import { SR_TerminaHabla } from './sdkSpeakAI.js';
// "DemoUniversidad Robotrón v1.1"
export var SR_recognition = null;
//var SR_recognitionActive = false;

var SR_timerDespiertaBot;
/*Varialbes usadas para determinar si está escuchando o procesando lo que escuchó, o hablando*/
var SR_HablandoAhora=false;
var SR_ProcesandoReconocimiento = false;
var SR_ActivarTimerReconociendo;    //Reactivar Timer cada 5 segundos
var SR_TimerReconociendo=5000;      //Variable que almacena el objeto setInterval
var SR_errorreconociendo=false;

var SR_TiempoParaFinalOracion=2500; //Entre palabras, toma el tiempo para determinar que hubo una oración completa
export var SR_TimerParaFinalOracion;           //y entonces la procesa para analizar su contenido

var SR_TiempoParaBalbuceo=5000;         //Para evitar el error undefined que se produce al reconocer un balbuceo
var SR_TimerParaBalbuceo;

/**
 * Enable speech recognition if supported by the browser, and insert the voice to text to the input field.
 * Optionally call click() on the button.
 */
var interimTranscript = ""; //Almacena los mensajes intermedios reconocidos 
export function SR_registerSpeechRecognition(input, button) 
	{
		if ('webkitSpeechRecognition' in window) 
			{   
				console.log("Registrando Speech Recognition");
				SR_recognition = new webkitSpeechRecognition();
				SR_recognition.lang = "es-AR";
				SR_recognition.continuous = true;
				SR_recognition.interimResults = true;
				SR_recognition.maxAlternatives = 1;             

				SR_recognition.onstart = function() {
					console.log("Iniciado el reconocimiento...onstart.");
					return;
					};

				SR_recognition.onspeechstart  = function(){
					console.log("Iniciado speech...onspeechstart");
					verGrafico('barras');
					SR_TimerParaBalbuceo=setTimeout(function(){SR_Restart();},SR_TiempoParaBalbuceo);
					return;
					};

				SR_recognition.onspeechend  = function(){
					console.log("Finalizado speech...onspeechend");
					if (interimTranscript==="") 
							{console.log("Reiniciando por interimTranscript nulo - SR_Proc:"+SR_ProcesandoReconocimiento+" SR_Habl:"+SR_HablandoAhora);
							//Reiniciando por interimTranscript nulo
							if (SR_ProcesandoReconocimiento===false && SR_HablandoAhora===false && SliderActivo===false) 
								{SR_ActivarTimerReconociendo= setTimeout(
									function(){SR_Restart();},1000);
								}
							}
					return;
					};

				SR_recognition.onerror = function(evento){
					//SR_recognitionActive = false;
					SR_errorreconociendo = true;
					console.log("¡Hubo algún error! onerror- "+evento.error+ "  Más detalles: "+evento.message);
					if (SR_ProcesandoReconocimiento===false && SR_HablandoAhora===false && SliderActivo===false) 
							{console.log("Reiniciando por error");
							//SR_recognition.stop();
							SR_ActivarTimerReconociendo= setTimeout(
									function(){SR_TerminaHabla();},500);
							}
					return;
					};

				SR_recognition.onresult = function(event) {
					//console.log("OnResult registrado");                   
					interimTranscript = "";
					for (var i = event.resultIndex; i < event.results.length; i++) {
						var _transcript = event.results[i][0].transcript;
						if (event.results[i].isFinal) {
							var finalTranscript = _transcript + ".";
							//console.log("Final: "+finalTranscript);
						} else {
							try{clearInterval(SR_TimerParaBalbuceo);}
								catch(e){console.log("No se pudo limpiar TimerBalbuceo");}
							interimTranscript += _transcript;
							//console.log("Intermedio: "+interimTranscript);                            
							SR_StartCheckTerminaEscucha(interimTranscript);
						}
					}
					return;
				};
			} 
		else {  return; }
	}

// Inicia el control de tiempo del silencio del usurio para determinar si debe procesar
// lo que el robot ha escuchado
function SR_StartCheckTerminaEscucha(texto)
	{      
		try{clearInterval(SR_TimerParaFinalOracion);}
		catch(e){console.log("No se pudo limpiar IntervalTiempoSilencio");}
		SR_TimerParaFinalOracion=setTimeout(
			function(){
				console.log("SR_Recognition.stop() y procesa mensaje.");
				SR_recognition.stop();              
				SR_ProcesandoReconocimiento=true;
				var preguntausr=texto;
				// if (MenuActivo===true) { 
				// 		PreProcesadorOpcionesMenu(texto);
				// 		return true;
				// 	} //Se está eligiendo opciones de un menú
				PreProcesadorMensajes(texto);
			}, SR_TiempoParaFinalOracion);
		return;
	}

function SR_Restart() 
	{
		if (interimTranscript==="") {
			try {
				SR_recognition.start();
				console.log("SR_recognition.restart() OK");             
			}
			catch(err) {
				console.log("SR_recognition.restart() Error");
			}
			finally{
				verGrafico('micescuchando');	
				SR_ProcesandoReconocimiento = false;
			}			
			//SR_recognitionActive = true;
		}
		return;
	}

export function asignaSR_HablandoAhora(status)
	{
		SR_HablandoAhora = status;
	}

export function asignaSR_ProcesandoReconocimiento(status)
	{
		SR_ProcesandoReconocimiento = status;
	}